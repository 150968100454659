// body {
//   font-family: 'Phetsarath OT', Helvetica, sans-serif;
// }
.scrollable-content::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 600px) {
    .ant-picker-panels {
      display: flex;
      flex-direction: column;
      height: 50;
    }
  }

.textColor {
    background-image: linear-gradient(43deg, #e358c3 0%, #813fd1 46%, #236bbd 100%);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
    font-size: x-large;
    font-style: italic;
  }

  @media only screen and (min-width: 3840px) {
    .main-component {
      height: 100%;
    }
  }

//   :where(.css-dev-only-do-not-override-11xg00t).ant-select-single .ant-select-selector .ant-select-selection-search {
//     position: absolute;
//     top: 0;
//     inset-inline-start: 11px;
//     inset-inline-end: 11px;
//     bottom: 0;
//     border: none;
//     border-radius: 30px;
// }
